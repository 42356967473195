body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background-image: url('https://s3.suporte-atendimento-vip.online/typebot/public/workspaces/cm2glnf610001dglfszge8uq5/typebots/cm2gsr88e001idglfh9wvghga/background?v=1729381599809');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-chat {
  background-color: #005e54;
}

.my-message {
  max-width: 80% !important;
  background-color: #e1ffc7;
  border-bottom-right-radius: 0.25rem;
  position: relative;
  border-radius: 10px 10px 0px 10px;
}

img {
  max-width: 65vw !important;
}

.send-message-button {
  background-color: #005e54;
}

.my-message::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -8px; /* Ajuste conforme necessário */
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 8px solid #e1ffc7; /* Mesma cor da bolha */
  border-bottom: 0;
  border-right: 0;
}

/* Estilos para a bolha de mensagem do remetente (esquerda) */
.their-message {
  max-width: 80% !important;
  background-color: #ffffff;
  color: #1f2937; /* Tailwind 'text-gray-800' */
  border-bottom-left-radius: 0.25rem; /* Remover o raio na parte inferior esquerda */
  position: relative;
  border-radius: 10px 10px 10px 0;
}

.their-message::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -8px; /* Ajuste conforme necessário */
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid #ffffff; /* Mesma cor da bolha */
  border-bottom: 0;
  border-left: 0;
}

.cont-then-profile-picture {
  margin-bottom: 1px;
  align-items: end;
}


.custom-audio-player {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 200px;
  padding: 8px;
}

.play-pause-button {
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #005e54;
}

.progress-bar-container {
  flex: 1;
  height: 4px;
  background-color: #e0e0e0;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #005e54;
  transition: width 0.1s linear;
}

.video {
  max-width: 500px !important;
}

.btn-message {
  background-color: #005e54;
}

@media (max-width: 500px) {
  .video {
    max-width: 65vw !important;
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;